export const home = [
    {
        path: '/home',
        name: "Home",
        component: () => import("../views/home/index.vue"),
        meta: { title: '用户中心' },
        children: [
            {
                path: 'user',
                name: 'User',
                component: () => import("../views/home/user.vue"),
                meta: { title: '用户中心' }
            },
            {
                path: 'equity',
                name: 'Equity',
                component: () => import("../views/home/equity.vue"),
                meta: { title: '运营后台' }
            },
            {
                path: 'upstage',
                name: 'Upstage',
                component: () => import("../views/home/upstage.vue"),
                meta: { title: '展商权益' }
            },
            {
                path: 'main',
                name: 'Main',
                component: () => import("../views/home/main.vue"),
                meta: { title: '关于我们' }
            }
            
        ]
    }
]