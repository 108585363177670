export const exhibiting = [
    {
        path: '/exhibiting',
        name: "Exhibiting",
        component: () => import("../views/background/index.vue"),
        meta: { title: '线下展厅' },
        children: [
            {
                path: 'booth',
                name: 'Booth',
                component: () => import("../views/exhibition/booth.vue"),
                meta: { title: '展位号' }
            },
            {
                path: 'activities',
                name: 'Activities',
                component: () => import("../views/exhibition/activities.vue"),
                meta: { title: '现场活动' }
            },
            {
                path: 'nobooth',
                name: 'Nobooth',
                component: () => import("../views/exhibition/noBooth.vue"),
                meta: { title: '展位号' }
            }
            
        ]
    }
]