import Vue from 'vue'
import VueRouter from 'vue-router'
import { database } from '../router/database.js'
import { exhibiting } from '../router/exhibition.js'
import { graphicExhibition } from '../router/graphicExhibition.js'
import { threeD } from '../router/three.js'
import { home } from '../router/home.js'
import Cookies from 'js-cookie'
import { Message } from 'element-ui';
Vue.use(VueRouter)


const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

const routes = [{
  path: '/',
  name: 'Login',
  component: () => import("../views/login/index.vue"),
},
{
  path: '/register',
  name: 'Register',
  component: () => import("../views/register/index.vue"),
},

{
  path: '/changepwd',
  name: 'Changepwd',
  component: () => import("../views/changepwd/index.vue"),
},
{
  path: '/phone',
  name: 'phone',
  component: () => import("../views/phone.vue"),
},
{
  path: '/background',
  name: 'Background',
  component: () => import("../views/background"),
  meta: {
    title: "首页"
  },
  children: [
    {
      path: 'exhibiting',
      name: 'Exhibiting',
      component: () => import('../views/exhibiting/index.vue'),
      meta: {
        title: "参展信息"
      }
    },
    {
      path: 'people',
      name: 'People',
      component: () => import('../views/people/index.vue'),
      meta: {
        title: "人员管理"
      }
    },
  ]

},
...database,
...exhibiting,
...graphicExhibition,
...home,
...threeD
]

const router = new VueRouter({
  routes
})

// 路由守卫判断是否存在token
router.beforeEach(function (to, from, next) {

  if (to.path === '/' || to.path === '/register' || to.path === '/changepwd') {

    next(); //登陆页面直接放行

  } else {

    let token = Cookies.get('token');//自己写的获取本地储存的方法

    if (token) {

      next(); //存在token 直接放行

    } else {

      Message.error('您的信息已过期');
      setTimeout(function () { next({ path: '/' }) }, 1000)
    }
  }

});


export default router
