export const threeD = [
    {
        path: '/threeD',
        name: "threeD",
        component: () => import("../views/background/index.vue"),

        children: [
            {
                path: 'styles',
                name: ' Styles',
                component: () => import("../views/3Dexhibition/styles.vue"),
                meta: { title: '3D展厅模型样式' }
            },
            {
                path: 'decoration',
                name: ' Decoration',
                component: () => import("../views/3Dexhibition/decoration.vue"),
                meta: { title: '3D展厅模型装饰' }
            },
            {
                path: 'manage3d',
                name: ' Manage3d',
                component: () => import("../views/3Dexhibition/manage3d.vue"),
                meta: { title: '3D展厅管理' }
            }
            
        ]
    }
]