import Vue from 'vue'
import Vuex from 'vuex'
import axios from "axios";
import Cookies from 'js-cookie'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    isOk:false,
    // 判断用户是否录入信息
    user:false,
    addData:false,
    token:Cookies.get('token'),
    username:localStorage.getItem('username'),
    useremail:localStorage.getItem('useremail'),
    table:'',
    queryId:"",
    //用户中心状态值
    iconId:false,
    noIcon:false,
    outIcon:false,
    // 申请参展状态值
    addIcon:false,
    phone:[],
    phonelist:[],
    chanpin:[],
    chanpinlist:[],
  },
  getters: {
    
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
