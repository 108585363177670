import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import "./assets/tailwindcss.css"
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import 'default-passive-events'
// import Pagination from "./components/Pagination.vue";
import  VueQuillEditor from 'vue-quill-editor'//调用编辑器
// 样式
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import moment from 'moment'
import 'normalize.css'
import './views/index.css'
Vue.prototype.$moment = moment;

Vue.use(VueQuillEditor)
// 定义全局变量，
Vue.prototype.$url = 'aaa';


Vue.config.productionTip = false
Vue.prototype.$http = axios

Vue.use(ElementUI);
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
