export const database = [
    {
        path: '/database',
        name: "Database",
        component: () => import("../views/background/index.vue"),
        meta: { title: '素材库' },
        children: [
            {
                path: 'onLine',
                name: 'OnLine',
                component: () => import("../views/database/onLine.vue"),
                meta: { title: '线上banner' }
            },
            {
                path: 'video',
                name: 'Video',
                component: () => import("../views/database/video.vue"),
                meta: { title: '公司视频' }
            },
            {
                path: 'graph',
                name: 'Graph',
                component: () => import("../views/database/graph.vue"),
                meta: { title: '产品信息' }
            },
            {
                path: 'album',
                name: 'Album',
                component: () => import("../views/database/album.vue"),
                meta: { title: '企业相册' }
            }
            
        ]
    }
]