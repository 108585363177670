<template>
  <div id="app" style="background: rgb(244, 247, 254)">
  <router-view />
  </div>
</template>
  
  
  
  <script>
export default {
  name: "App",

  provide() {
    return {
      // reload: this.reload,
    };
  },

  data() {
    return {
      // isReload: true,
    };
  },

  methods: {

  },
};
</script>

<style >


</style>