export const graphicExhibition = [
    {
        path: '/graphicExhibition',
        name: "GraphicExhibition",
        component: () => import("../views/background/index.vue"),
        meta: { title: '平面展厅' },
        children: [
            {
                path: 'app',
                name: ' App',
                component: () => import("../views/graphicExhibition/app.vue"),
                meta: { title: '装修管理' }
            },
            {
                path: 'news',
                name: ' News',
                component: () => import("../views/graphicExhibition/news.vue"),
                meta: { title: '企业新闻' }
            },
            {
                path: 'addForum',
                name: ' AddForum',
                component: () => import("../views/graphicExhibition/addforum.vue"),
                meta: { title: '添加论坛' }
            }
            
        ]
    }
]